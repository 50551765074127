.header {
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    
}
.logo {
    width: 6%;
}
.header-bg {
    background-color: rgba(28, 58, 91, 0.9);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: #ffffff;
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--secondary-color);
}

.hamburger {
    display: none;
}

.banner {
    // // // background-color: var(--overlay);
    background-color: #1c3a5b;
    // // color: #fff;
    // // // height: 100vh;
    // // // width: 100%;
    // // position: relative;
    // // align-items: center;
    // // justify-content: center;
    // // display: flex;
    // // border: 2px solid red;
    // // .imagen {
    // //     border: 0;
    // // }
}

.dnone {
    display: none;
}

.dblock{
    display: block;
}

.top-to-btm{
    position: fixed; 
    width: 100%;
    bottom: 40px;
    height: 40px;
    justify-content: left;
    z-index: 1;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.9;
    align-items: flex-end;
    text-align: end;
    right: 1%;
}
// .bgimage-seccion2 {
//     width: 100%;
//     height: auto;
//     background: url('./assets/img/deporte.jpg');
//     background-repeat: no-repeat;
//     background-position: 50%;
//     background-size: cover;
//     background-attachment: fixed;
//     position: relative;
//     text-align: center;
//     margin: 0 auto;
// }
.top-to-cita{
    position: absolute; 
    bottom: 150px;
    left: 190px;
    border: none;
    z-index: 10;
    // right: 40%;
    // width: 100%;
    // bottom: 40px;
    // height: 40px;
    // justify-content: left;
    // z-index: 1;
    // animation: fadeIn 0.3s;
    // transition: opacity 0.4s;
    // opacity: 0.9;
    // align-items: flex-end;
    // text-align: end;
    // right: 1%;

}

.zl-url {
    width: 10%;
}

.polygon_1 {
   height: 80px;
   top:0;
   margin-top: 0;
   background-color: #1c3a5b;
   position: relative;
}
.polygon_2 {
    height: 80px;
    top:0;
    margin-top: 0;
    background-color: #ffffff;
    position: relative;
 }
 .polygon2{
    fill: #143a72
    //rgba(28, 58, 91, 0.9);
 }
 .icon_nav{  
    width: 10px;
    height: 10px;
    // object-fit: cover;
 }
.servicios {
    background-image: url('./assets/img/deportes.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; 
    .interno {
        width: 100%;
        background: rgba(7, 49, 111, 0.9);
    }
}
.informativo{
    background-image: url('./assets/img/CMO.jpeg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover; 
    .interno {
        width: 100%;
        background: rgba(7, 49, 111, 0.9);
    }
}


.scroll {
    background-position: 10px 10px;
}

.modal-informate{
    position: fixed;
    z-index: 1000;
    top:50%;
    left:50%;
    padding: 30%;
    background-color: #407bd3;
    transform: translate(-50%, -50%);
}
.modal-overlay{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 1000;
}

@media screen and (max-width:940px) {
    .header {
        max-width: 100%;
        background-color: rgba(19, 96, 159, 0.9);
        // background-color: transparent;
        height: 50px;
    }
    .logo {
        width: 15%;
        // display: none;
    }

    .header .navbar {
        max-width: 100%;
    }

    .header-bg {
        background-color: rgba(28, 58, 91, 0.9);
    }

    .hamburger {
        display: block;
    }
    /*NAVBAR*/
    /**/

    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        background-color: rgba(0,0,0,.9);
        width: 100%;
        height: 90vh;
        z-index: 999;
        text-align: center;
        transition: .3s;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 1.5rem 0;
    }
    .opinion{
        font-size: 20px;
    }

    // .header .navbar img {
    //     width: 150px;
    // }
}






